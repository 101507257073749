import store from '../../store';
import axios from 'axios';

export default async() => {
  let result = {};
  result.error = false;
  result.data = [];
  let defaultLanguage = '';
  if (store.state.defaultLanguage !== null) {
    defaultLanguage = (store.state.defaultLanguage).toLowerCase();
  }

  await axios({
    url: `${window.cipConfig.VUE_APP_API_ENDPOINT}app/dashboard/donation-statistics`,
    method: 'GET',
    headers: {
      'X-localization': defaultLanguage,
      'token': store.state.token
    }
  }).then((response) => {
    result.error = false;
    result.message = response.data.message;
    if (response.data.data) {
      result.data = response.data.data;
    }
  }).catch(() => result.error = true);

  return result;
}