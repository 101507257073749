import API from '@/api';
import qs from 'qs';

class User
{
  /**
   * Do api call to get all user mission applications
   *
   * @return {promise}
   */
  static async getApplications(payload) {
    return await API.dispatch(
      'GET',
      'userApplications',
      {
        query: payload,
        custom: {
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        }
      }
    );
  }

  /**
   * Do api call to get all user matching gift statistics
   *
   * @return {promise}
   */
  static async getMatchingGiftStats(payload) {
    return await API.dispatch(
      'GET',
      'matchingGiftStats'
    );
  }

  /**
   * Do api call to get all user mission applications timesheet
   *
   * @return {promise}
   */
  static async getApplicationsTimesheet(payload) {
    return await API.dispatch(
      'GET',
      'userApplicationsTimesheet',
      {
        query: payload,
        custom: {
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        }
      }
    )
  }

  /**
   * Do api call to pre-login the user
   *
   * @return {promise}
   */
  static async preLogin(payload) {
    return await API.dispatch(
      'POST',
      'preLogin',
      {
        data: payload
      }
    );
  }

  /**
   * Do api call to pre-transmute the user
   *
   * @return {promise}
   */
  static async preTransmute(payload) {
    return await API.dispatch(
      'POST',
      'preTransmute',
      {
        data: payload
      }
    );
  }

  /**
   * Do api call to pre-login the user
   *
   * @return {promise}
   */
  static async signConsent(payload) {
    return await API.dispatch(
      'POST',
      'signConsent',
      {
        data: payload
      }
    );
  }
}

export default User;