import API from '@/api';

class Team
{
  /**
   * Do api call to get all teams the user belongs to
   *
   * @return {promise}
   */
  static async listTeamUsers(query) {
    return await API.dispatch(
      'GET',
      'teamUsers',
      {
        query
      }
    );
  }

  /**
   * Do api call to get the team details
   *
   * @return {promise}
   */
  static async getTeamDetails(teamId) {
    return await API.dispatch(
      'GET',
      'team',
      {
        path: { teamId }
      }
    );
  }

  /**
   * Do api call to store/update the team details
   *
   * @return {promise}
   */
  static async saveTeam(params, teamId) {
    return await API.dispatch(
      'POST',
      teamId ? 'team' : 'teams',
      {
        ...(teamId) && { path: { teamId } },
        data: params
      },
      {
        'Content-Type': 'multipart/form-data'
      }
    );
  }

  /**
   * Do api call to bulk store/update the team users
   *
   * @return {promise}
   */
   static async bulkAddTeamMembers(params) {
    return await API.dispatch(
      'POST',
      'teamUsers',
      {
        data: params
      },
      {
        'Content-Type': 'application/json'
      }
    );
  }

  /**
   * Do api call to archive the team
   *
   * @return {promise}
   */
   static async archive(params) {
    return await API.dispatch(
      'POST',
      'archive',
      {
        data: params
      },
      {
        'Content-Type': 'multipart/form-data'
      }
    );
  }
}

export default Team;