import axios from 'axios'

const ACCEPTED_FILTERS = {
  'search': 'search',
  'countryId': 'country_id',
  'cityId': 'city_id',
  'stateId': 'state_id',
  'themeId': 'theme_id',
  'skillId': 'skill_id',
  'sortBy': 'sort_by',
  'currentView': 'current_view',
  'organizationId': 'organization_id',
  'type': 'type',
  'subTypeId': 'sub_type',
  'from': 'form',
  'to': 'to',
  'exploreMissionType': 'explore_mission_type',
  'exploreMissionParams': 'explore_mission_params'
};

export function buildFilter(data) {
  const filters = {};
  const supportedFilters = Object.keys(ACCEPTED_FILTERS);

  Object.keys(data).forEach(key => {
    const value = data[key] || null;
    if (!value || !supportedFilters.includes(key)) {
      return;
    }
    filters[ACCEPTED_FILTERS[key]] = value;
  });

  return filters;
}

export default (data) => {
  axios({
    url: window.cipConfig.VUE_APP_API_ENDPOINT + "app/user-filter",
    data: buildFilter(data),
    method: 'post'
  });
}