/**
 * Donation services
 * Responsible for all API calls of the application
 */
import { retrieveObject } from '@/utils';

const requireContext = require.context(
  './User',
  false,
  /.*\.js$/
);

/**
 * TODO: find a way to export function result. The export must be dynamic
 * To be able to import this file like import { Service1, Service2 } from 'user'
 */
export const {
  Main
} = retrieveObject(requireContext);