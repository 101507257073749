import axios from 'axios';
import store from '../../store';

export default async page => {
  const responseData = {};
  let defaultLanguage = '';
  if (store.state.defaultLanguage !== null) {
    defaultLanguage = store.state.defaultLanguage.toLowerCase();
  }
  const url = `${window.cipConfig.VUE_APP_API_ENDPOINT}app/contact-us/topic`;
  await axios({
    url,
    method: 'GET',
    headers: {
      'X-localization': defaultLanguage,
    },
  })
    .then(response => {
      responseData.error = false;
      responseData.message = response.data.message;
      responseData.data = response.data.data;
    })
    .catch(error => {
      if (error.response.data.errors[0].message) {
        responseData.error = true;
        responseData.message = error.response.data.errors[0].message;
      }
    });
  return responseData;
};
