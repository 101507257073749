import API from '@/api';
import qs from 'qs';

class Main
{
  /**
   * Do api call on specified method and endpoint
   *
   * @param {string} id Id of the needed mission
   *
   * @return {promise}
   */
  static async get(id, query = {}) {
    return await API.dispatch(
      'GET',
      'mission',
      {
        path: { id },
        query
      }
    );
  }

  static async getQuestions(id) {
    return await API.dispatch(
      'GET',
      'missionQuestions',
      {
        query: {
          mission_id: id
        }
      }
    );
  }

  static async isDonationEligible(id) {
    return await API.dispatch(
      'GET',
      'missionDonationEligible',
      {
        path: { id }
      }
    );
  }

  static async getShifts(query, id) {
    return await API.dispatch(
      'GET',
      'missionShifts',
      {
        query,
        path: { id }
      }
    );
  }

  static async timesheetSummary(payload) {
    return await API.dispatch(
      'GET',
      'missionsTimesheetsSummary',
      {
        query: payload,
        custom: {
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        }
      }
    );
  }
}

export default Main;