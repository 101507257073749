import store from '../../store'
import axios from 'axios'
const qs = require('qs');

export default async data => {
  let responseData = {}
  let defaultLanguage = ''
  if (store.state.defaultLanguage !== null) {
    defaultLanguage = store.state.defaultLanguage.toLowerCase()
  }
  let url = `${window.cipConfig.VUE_APP_API_ENDPOINT}app/missions/${data.mission_id}/pre-screening`;

  const options = {
    encode: false,
    skipNulls: true,
    addQueryPrefix: true
  };

  let dataString = qs.stringify(data, options);

  if (dataString) {
    url = `${url}${dataString}`;
  }

  if (!data.questionnaire_type) {
    url = `${url}&questionnaire_type`;
  }

  await axios({
    url,
    method: 'get',
    headers: {
      'X-localization': defaultLanguage
    }
  })
    .then(response => {
      responseData.error = false
      if (response.data.data) {
        responseData.data = response.data.data
      } else {
        responseData.data = []
      }
    })
    .catch(() => {
      responseData.error = true
    })
  return responseData
}
