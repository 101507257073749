import store from '../../store'
import axios from 'axios'

export default async data => {
  let responseData = {}
  let defaultLanguage = ''

  if (store.state.defaultLanguage !== null) {
    defaultLanguage = store.state.defaultLanguage.toLowerCase()
  }
  let dataPost = {}
  if (data.user_answers) {
    dataPost.user_answers = data.user_answers;
    dataPost.timesheet_id = data.timesheet_id;
  }

  let url = `${window.cipConfig.VUE_APP_API_ENDPOINT}app/mission/${data.missionId}/post-mission`

  await axios({
    url,
    data: dataPost,
    method: 'post',
    headers: {
      'X-localization': defaultLanguage
    }
  })
    .then(response => {
      responseData.error = false
      responseData.message = response.data.message
      responseData.data = response.data.data
    })
    .catch(error => {
      if (error.response.data.errors[0].message) {
        responseData.error = true
        responseData.message = error.response.data.errors[0].message
        responseData.data = ''
      }
    })
  return responseData
}
